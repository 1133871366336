











import { Component, Mixins } from 'vue-property-decorator'

import EventScheduleQuestionForm from '@/partials/forms/Events/ScheduleQuestionForm.vue'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    EventScheduleQuestionForm
  }
})
export default class EventScheduleQuestionEdit extends Mixins(PermissionsMixin) {
}
